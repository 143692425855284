import { z } from "zod";

const LicenseTypeSchema = z.enum(["temporary", "persistent"]);
const DrmIntegrationIdSchema = z.enum(["vudrm", "ezdrm"]);

const KeySystemConfigurationSchema = z.object({
  licenseAcquisitionURL: z.string().url(),
  licenseType: LicenseTypeSchema.optional(),
  headers: z.record(z.string()).optional(),
  useCredentials: z.boolean().optional().default(false),
  queryParameters: z.record(z.any()).optional(),
});

const WidevineKeySystemConfigurationSchema = KeySystemConfigurationSchema;

const DrmConfigurationSchema = z.object({
  integration: DrmIntegrationIdSchema.optional(),
  widevine: WidevineKeySystemConfigurationSchema,
  preferredKeySystems: z.array(z.string()),
});
// .passthrough();

const DrmLicenseConfigSchema = z.object({
  licenseAcquisitionURL: z.string().url(),
  useCredentials: z.boolean(),
});

const SourceSchema = z.object({
  contentProtection: DrmConfigurationSchema.optional(),
  drm: DrmConfigurationSchema,
  src: z.string().url(),
});

// ---

export const CustomDrmIntegrationIdSchema = z.enum([
  "vudrm",
  "ezdrm",
  "custom",
  "keyos",
  "keyos_buydrm",
]);

const SlimDrmSchema = z.object({
  licenseAcquisitionURL: z.string().url(),
  integration: CustomDrmIntegrationIdSchema.optional(),
  headers: z.record(z.string()).optional(),
});
// .passthrough();

export const CustomDrmConfigurationSchema = DrmConfigurationSchema.extend({
  integration: CustomDrmIntegrationIdSchema.optional(),
  customIntegrationId: z.string().optional(),
  integrationParameters: z.record(z.unknown()).optional(), // Flexible to handle various integration parameters
  playready: DrmLicenseConfigSchema.optional(),
  preferredKeySystems: z.array(z.string()).optional(),
});

const AbrSchema = z.object({
  preferredAudioCodecs: z.array(z.string()).optional(),
});

const ExtendedSourceSchema = SourceSchema.extend({
  src: z.string().url(),
  type: z.string().optional(),
  contentProtection: DrmConfigurationSchema,
  abr: AbrSchema.optional(),
  crossOrigin: z.string().optional().nullable(),
  hlsDateRange: z.boolean().nullish(),
  experimentalRendering: z.boolean().optional(),
  ignoreEmbeddedTextTrackTypes: z.array(z.string()).optional(),
  lowLatency: z.boolean().optional(),
  nativeUiRendering: z.boolean().optional(),
  useCredentials: z.boolean().optional(),
  useNativePlayback: z.boolean().optional(),
});

const CustomSourceSchema = z
  .union([
    ExtendedSourceSchema.omit({ drm: true }).merge(
      z.object({
        contentProtection: CustomDrmConfigurationSchema,
      })
    ),
    ExtendedSourceSchema.omit({ contentProtection: true }).merge(
      z.object({
        drm: CustomDrmConfigurationSchema,
      })
    ),
  ])

  /**
   * Native apps send sources without drm property instead of contentProtection and without `widevine` prop
   * if that's the case populate contentProtection and `widevine`.
   */
  .transform((val) =>
    "drm" in val ? { ...val, contentProtection: val.drm } : val
  );

const AdsSchema = z.array(z.unknown());
const TextTracksSchema = z.array(z.unknown());
const EmptyPosterSchema = z.string().transform(() => undefined);

const SourceDescriptionSchema = z.object({
  ads: AdsSchema.optional(),
  poster: z.union([z.string().url().optional(), EmptyPosterSchema]).optional(),
  sources: z.array(CustomSourceSchema),
  textTracks: TextTracksSchema.optional(),

  // Adding "passthrough()" is causing "unknown"
  analytics: z.unknown(),
  blockContentIfAdError: z.unknown(),
});
// .passthrough();

const StringToJsonSchema = z.string().transform((str, ctx) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    ctx.addIssue({
      code: "custom",
      message: "Invalid JSON format in string",
    });
    return z.NEVER;
  }
});

// Check if a string is JSON, if so parse it, otherwise keep it as a string
const StringToJsonCheckSchema = z.string().transform((str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
});

/**
 * This seems to be the case for iOS (no `sourceDescription` key) but not
 * Android (`sourceDescription` key present), so let's check for both.
 */
const PlatformSpecificCustomDataSchema = z.union([
  z
    .object({
      sourceDescription: z.union([
        StringToJsonSchema.pipe(SourceDescriptionSchema),
        SourceDescriptionSchema,
      ]),
    })
    .passthrough()
    .transform(({ sourceDescription, ...val }) => ({
      ...sourceDescription,
      ...val,
    })),
  SourceDescriptionSchema,
]);

export const CustomDataPropSchema = z.union([
  StringToJsonSchema.pipe(PlatformSpecificCustomDataSchema),
  PlatformSpecificCustomDataSchema,
  SlimDrmSchema,
]);

const MediaSchema = z.object({
  contentId: z.string(),
  contentUrl: z.string().optional(),
  // Sometimes the string value is presented JSON encoded e.g. `\"application/dash+xml\"`
  contentType: StringToJsonCheckSchema,
});

export const RequestDataSchema = z
  .object({
    media: MediaSchema.passthrough(),
    customData: CustomDataPropSchema.optional(),
  })
  .passthrough();
